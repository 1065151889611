import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    auctions: [],
    auction_offers: [],
    current_lot: null,
  },
  getters: {
    getAuctions(state) { return state.auctions },
    getAuctionOffers(state) { return state.auction_offers },
    getCurrentLot(state) { return state.current_lot },
  },
  mutations: {
    SET_AUCTIONS(state, data) { state.auctions = data },
    SET_AUCTION_OFFERS(state, data) { state.auction_offers = data },
    SET_CURRENT_LOT(state, data) { state.current_lot = data },
  },
  actions: {
    async fetchAuctions({ commit }) {
      const response = await axiosIns.get('/auctions')
      commit('SET_AUCTIONS', response.data)
    },
    async fetchCurrentLot({ commit }) {
      const auction = localStorage.getItem('auctionId')
      const response = await axiosIns.get(`/auctions-current-lot/${auction}`)
      commit('SET_CURRENT_LOT', response.data)
    },
    async fetchAuctionOffers({ commit }, auctionId) {
      if (auctionId && auctionId !== '') {
        localStorage.setItem('auctionId', auctionId)
      }
      const user = JSON.parse(localStorage.getItem('userData'))
      const auction = localStorage.getItem('auctionId')
      const payload = {
        auctionId: auction,
        userId: user.id,
      }
      const response = await axiosIns.post('/auctions-offers', payload)
      localStorage.setItem('auction_offers', JSON.stringify(response.data.data))
      commit('SET_AUCTION_OFFERS', response.data.data)
    },
  },
}
